import React, { CSSProperties } from 'react'
import Box from '../../layout/Box'
import * as IconMap from './IconMap'
import * as Color from '../../helpers/colors'

export type IconName = keyof typeof IconMap

const nudge = (props: Partial<IconProps>): CSSProperties => {
  if (
    !(props.nudgeUp || props.nudgeDown || props.nudgeRight || props.nudgeLeft)
  )
    return

  return {
    position: 'relative',
    top: props.nudgeDown,
    left: props.nudgeRight,
    right: props.nudgeLeft,
    bottom: props.nudgeUp,
  }
}

type Props = {
  // An icon will inherit its container's color unless specified
  color?: Color.Type
  colorWeight?: number

  width?: number | string
  height?: number | string

  marginLeft?: number
  marginTop?: number

  nudgeUp?: number
  nudgeDown?: number
  nudgeLeft?: number
  nudgeRight?: number
}

const SVGWrapper = ({
  children,
  width,
  height,
  color,
  colorWeight = 0,
  marginLeft,
  marginTop,
  ...props
}: Props & { children: JSX.Element }) => {
  let colorStyle = 'inherit'
  if (color) {
    colorStyle = Color[color](colorWeight)
  }

  return (
    <Box
      style={{
        ...nudge(props),
        display: 'flex',
        justifyContent: 'center',
        flexBasis: width || 'auto',
        flexShrink: 0,
        width: width || '1em',
        height: height || (width ? 'fit-content' : '1em'),
        fill: 'currentColor',
        color: colorStyle,
        marginLeft,
        marginTop,
      }}
    >
      {children}
    </Box>
  )
}

type IconProps = Props & { name: IconName }
const Icon = ({ name, ...props }: IconProps) => {
  return <SVGWrapper {...props}>{IconMap[name]}</SVGWrapper>
}

type SVGProps = Props & { svg: JSX.Element }
const SVG = ({ svg, ...props }: SVGProps) => {
  return <SVGWrapper {...props}>{svg}</SVGWrapper>
}

export { SVGWrapper, Icon, SVG }
export default Icon
