import { classes, style, keyframes, stylesheet } from 'typestyle'
import * as Color from '../../helpers/colors'
import Style from './button.module.css'
import Icon, { IconName } from '../icons/Icon'
import Box from '../../layout/Box'

const spinAnimation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const spinningClass = style({
  animationName: spinAnimation,
  animationDuration: '1.3s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
})

const getPadding = ({
  appearance,
  icon,
  text,
  width,
  height,
}: Partial<Props>) => {
  if (appearance === 'text') return 0
  let left = 0 as number | string
  let right = 0 as number | string
  let top = 0 as number | string
  let bottom = 0 as number | string
  if (!width) {
    left = icon ? '0.4em' : '1.2em'
    right = icon && text ? '0.9em' : icon ? '0.4em' : '1.2em'
  }
  if (!height && text) {
    top = '0.8em'
    bottom = '0.8em'
  }
  return `${top} ${right} ${bottom} ${left}`
}

const getIconSize = ({ iconSize, fontSize, height }: Partial<Props>) => {
  if (iconSize) return iconSize
  if (fontSize) return fontSize + 2
  if (height) return Math.max(height - 14, 15)
  return 15
}

type Props = {
  text?: string | number
  onClick?: React.MouseEventHandler
  submit?: boolean
  appearance?: 'solid' | 'outline' | 'text'
  fontWeight?: number
  color?: Color.Type
  colorWeight?: number
  disabled?: boolean
  loading?: boolean
  minWidth?: React.CSSProperties['minWidth']
  width?: React.CSSProperties['width']
  height?: number
  fontSize?: number
  marginLeft?: number
  marginTop?: number
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
  href?: string
  icon?: IconName | null
  iconSize?: number
  iconColor?: Color.Type
  iconColorWeight?: number
  iconMargin?: number
  disableOnHover?: boolean
  disableOnActive?: boolean
  /** Cautiously add a custom className */
  className?: string
  /** Cautiously override styles */
  style?: React.CSSProperties
}

/**
 * Component
 */

const Button = ({
  appearance = 'solid',
  color = 'primary',
  icon,
  iconSize,
  text,
  onClick,
  submit = false,
  fontWeight = 700,
  colorWeight = 500,
  disabled = false,
  loading = false,
  marginLeft,
  marginTop,
  minWidth,
  width,
  height,
  fontSize,
  iconMargin = 0,
  href,
  textTransform,
  iconColor,
  iconColorWeight,
  className = '',
  disableOnActive = false,
  disableOnHover = false,
  style: _style = {},
}: Props) => {
  iconSize = getIconSize({ iconSize, fontSize, height })
  icon = loading ? 'SpinnerDots' : icon

  const colors = {
    base: Color[color](colorWeight),
    lighter: Color[color](colorWeight - 100),
    darker: Color[color](colorWeight + 100),
  }
  let propClass = style({
    outlineColor: colors.base,
    fontWeight,
  })

  let appearanceClass: string = ''
  if (appearance === 'outline') {
    appearanceClass = style({
      backgroundColor: 'transparent',
      border: `2px solid ${colors.base}`,
      color: colors.base,
      $nest: {
        '&:hover': {
          ...(!disableOnHover && {
            backgroundColor: colors.lighter,
            borderColor: colors.lighter,
            color: 'white',
          }),
        },
        '&:active': {
          ...(!disableOnActive && {
            backgroundColor: colors.base,
            borderColor: colors.base,
            outlineWidth: 2,
            color: 'white',
          }),
        },
      },
    })
  } else if (appearance === 'solid') {
    appearanceClass = style({
      backgroundColor: colors.base,
      border: `2px solid ${colors.base}`,
      color: 'white',
      $nest: {
        '&:hover': {
          ...(!disableOnHover && {
            backgroundColor: colors.lighter,
            borderColor: colors.lighter,
          }),
        },
        '&:active': {
          ...(!disableOnActive && {
            backgroundColor: colors.base,
            borderColor: colors.base,
            outlineWidth: 2,
          }),
        },
      },
    })
  } else if (appearance === 'text') {
    appearanceClass = style({
      outline: 'none',
      backgroundColor: 'transparent',
      color: colors.base,
      $nest: {
        '&:hover': {
          ...(!disableOnHover && {
            color: colors.lighter,
          }),
        },
        '&:active': {
          ...(!disableOnActive && {
            color: colors.base,
          }),
        },
      },
    })
  }

  const node = (
    <button
      className={classes(Style.button, appearanceClass, propClass, className)}
      onClick={(e) => {
        if (href) e.stopPropagation()
        if (loading || disabled) return
        if (onClick) onClick(e)
      }}
      disabled={disabled}
      {...(submit && {
        type: 'submit',
      })}
      style={{
        width,
        fontSize,
        height,
        textTransform,
        minWidth: width || minWidth || 'max-content',
        padding: getPadding({ appearance, icon, text, width, height }),
        marginLeft,
        marginTop,
        ...(disabled
          ? {
              pointerEvents: 'none',
              opacity: 0.3,
            }
          : {}),
        ...(loading && {
          pointerEvents: 'none',
        }),
        ..._style,
      }}
    >
      {(icon || loading) && (
        <Box
          className={loading ? spinningClass : undefined}
          style={{
            fontSize: iconSize,
            marginRight: iconMargin || (text ? '0.3em' : 0),
          }}
        >
          <Icon
            color={iconColor}
            colorWeight={iconColorWeight}
            name={icon}
            nudgeUp={appearance === 'text' ? 1 : 0}
            height={iconSize}
            width={iconSize}
          />
        </Box>
      )}
      {text}
    </button>
  )

  return href ? (
    <a
      // Reset styles
      style={{
        color: 'inherit',
        background: 'inherit',
        fontSize: 'inherit',
        opacity: 'inherit',
        position: 'relative',
        textDecoration: 'none',
      }}
      target="_blank"
      href={href}
    >
      {node}
    </a>
  ) : (
    node
  )
}

export default Button
export { Button }
export type ButtonProps = Props
