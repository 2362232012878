import * as Types from '../types'
import bowser from 'bowser'
import { format } from 'date-fns'
import camelCase from 'lodash/camelCase'
import segment from '@segment/snippet'

const ua = bowser.parse(navigator.userAgent)

const COOKIE_NAME = '_lsa'

const getCookie = (
  document: HTMLDocument,
  userId: string,
  cookieName: string = COOKIE_NAME,
) => {
  try {
    if (!document || !cookieName) {
      return null
    }
    const cookie = document.cookie
      .split(';')
      .map((j) => j.trim())
      .find((x) => x.startsWith(`${cookieName}=`))
    if (!cookie) return null
    const re = new RegExp(`^${cookieName}=`)

    const cookieValue = cookie.replace(re, '')
    const decodedCookie = atob(cookieValue)
    return JSON.parse(decodedCookie)
  } catch (e) {
    // TODO: error handle here, maybe with sentry
    return null
  }
}

const cookieMap: { [key: string]: string } = {
  'Initial Marketing Source': 'utm_source',
  'Initial Marketing Medium': 'utm_medium',
  'Initial Marketing Campaign': 'utm_campaign',
  'Initial Marketing Term': 'utm_term',
  'Initial Marketing Content': 'utm_content',
  'Initial Marketing Referrer': 'referrer',
  'Initial Marketing Landing Page': 'page_url',
}

const setCookie = (cookieName: string, cookieProps: object) => {
  const encodedCookieString = btoa(JSON.stringify(cookieProps))
  const domain = `.${window.location.host.split('.').slice(-2).join('.')}`
  const sameSite = 'lax'
  const expires = 'Fri, 31 Dec 9999 23:59:59 GMT'
  const cookie = `${cookieName}=${encodedCookieString};domain=${domain};secure;samesite=${sameSite};expires=${expires}`

  return cookie
}

const makeCookieObject = (document: HTMLDocument) => {
  const params: { [key: string]: any } = {
    referrer: document.referrer || null,
    page_url: document.URL,
  }

  const currentUrl = new URL(window.location.toString())

  Object.keys(cookieMap).forEach((key) => {
    params[key] = currentUrl.searchParams.get(cookieMap[key])
  })

  return params
}

type Product = {
  color: string
  description: string
  name: string
  tagline: string
  features: any[]
  profiles?: Array<{ productKey: string; name: string }>
}

const productDescription: { [key: string]: Product } = {
  mixer_720_30: {
    color: '#786FD1',
    description: '720P 30FPS',
    name: 'Gamer',
    tagline: 'Console streams with overlays and alerts',
    features: [],
    profiles: [
      { productKey: 'mixer_720_30', name: '720p 30FPS' },
      { productKey: 'mixer_720_60', name: '720p 60FPS' },
      { productKey: 'mixer_1080_30', name: '1080p 30FPS' },
    ],
  },
  mixer_720_60: {
    color: '#786FD1',
    description: '720P 60FPS',
    name: 'Gamer',
    tagline: 'Console streams with overlays and alerts',
    features: [],
  },
  mixer_1080_30: {
    color: '#786FD1',
    description: '1080P 30FPS',
    name: 'Gamer',
    tagline: 'Console streams with overlays and alerts',
    features: [],
  },
  creator_720_30: {
    color: '#26AD80',
    description: '720P 30FPS',
    name: 'Creator',
    tagline: 'Advanced live video production',
    features: [],
    profiles: [
      { productKey: 'creator_720_30', name: '720p 30FPS' },
      { productKey: 'creator_720_60', name: '720p 60FPS' },
      { productKey: 'pro_720_60', name: '1080p 30FPS' },
    ],
  },
  creator_720_60: {
    name: 'Creator',
    description: '720P 60FPS',
    color: '#26AD80',
    tagline: 'Advanced live video production',
    features: [],
  },
  creator_1080_30: {
    description: '1080P 30FPS',
    name: 'Creator',
    color: '#26AD80',
    tagline: 'Advanced live video production',
    features: [],
  },
  // This is currently used for lookups by role
  pro_720_30: {
    name: 'Creator',
    color: '#26AD80',
    description: '1080P 30FPS',
    tagline: 'Advanced live video production',
    features: [],
  },
  pro_720_60: {
    name: 'Creator',
    color: '#26AD80',
    description: '1080P 30FPS',
    tagline: 'Advanced live video production',
    features: [],
  },
}

export const identifyUser = (user: Types.User, app: Types.App) => {
  if (!user || !user.id) {
    return
  }

  if (app.referralHero.enabled) {
    initializeReferralHero(app.referralHero.uuid).then((RH) => {
      if (
        user.roles.includes('vip') ||
        (user.subscription && user.subscription.status !== 'cancelled')
      ) {
        RH.identify(
          {
            email: user.email,
            extra_field: user.id,
            upsert: true,
          },
          false,
        )
      }
    })
  }

  const kustomerTokenCopy: string = user.kustomerToken

  let analyticsCookie = getCookie(document, user.id, COOKIE_NAME)
  if (!analyticsCookie) {
    analyticsCookie = makeCookieObject(document)
    document.cookie = setCookie(COOKIE_NAME, analyticsCookie)
  }
  let planName: string, paymentType: string
  const hasActiveSub =
    user.subscription && user.subscription.status !== 'cancelled'
  if (hasActiveSub) {
    const productKey = Object.keys(productDescription).find(
      (productName) => user.subscription.planId.indexOf(productName) > -1,
    )
    if (productKey) {
      const product = productDescription[productKey]
      planName = `${product.name} - ${product.description}`
      if (user.subscription.planId.indexOf('monthly') > -1) {
        paymentType = 'monthly'
      } else {
        paymentType = 'annual'
      }
    } else {
      planName = user.role
      paymentType = null
    }
  } else {
    planName = user.role
    paymentType = null
  }
  const identify = {
    name: user.displayName,
    email: user.email,
    'Current Product': 'Studio 2',
    'Command Link': `https://command.lightstream.dev/#/users/${user.id}`,
    'Destinations Connected': '',
    'Segmentation Category': 'No Category',
    'Plan Name': planName,
    'Payment Type': paymentType,
    'Signed Up': format(new Date(user.createdAt), 'MMMM Do, yyyy'),
    'Last Seen': format(Date.now(), 'MMMM Do, yyyy'),
    Browser: `${ua.browser.name} ${ua.browser.version}`,
    ...analyticsCookie,
  }

  if (window.Appcues) {
    window.Appcues.identify(user.id, identify)
  }

  window.addEventListener('kustomerLoaded', function () {
    window.Kustomer.start(
      {
        brandId: '62d9b17227d7170909fecaff',
        chatIconSize: 36,
        chatIconPosition: {
          horizontalPadding: -5,
          verticalPadding: 20,
        },
      },
      async function () {
        if (kustomerTokenCopy !== null) {
          window.Kustomer.login(
            {
              jwtToken: kustomerTokenCopy,
            },
            (loginCallbackResponse: any, error: any) => {
              if (!error) {
                kustomerIdentify(user, identify)
              }
            },
          )
        } // if user.kustomerToken
      },
    )
  })
  const script = document.createElement('script')
  script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js'
  script.setAttribute('data-kustomer-api-key', app.kustomerApiKey)
  document.body.append(script)

  if (app.segment.enabled) {
    const contents = segment.min({
      host: 'cdn.segment.com',
      apiKey: app.segment.apiKey,
      load: true,
      page: {
        category: 'Studio2',
      },
    })
    const script = document.createElement('script')
    script.innerHTML = contents
    document.body.append(script)
    window.analytics.identify(user.id, identify)
  }
}
const kustomerIdentify = (user: Types.User, identify: any) => {
  const { email } = user
  const kustomerData: Kustomer = { emails: email }
  const kustomerVar = 'Str' // Kustomer adds this to every single-line text custom attribute

  Object.keys(identify).forEach((key) => {
    const newKey = camelCase(key).concat(kustomerVar)
    if (!(identify[key] == null || identify[key] == ''))
      kustomerData[newKey as keyof typeof kustomerData] = identify[key]
  })
  // @ts-ignore
  window.Kustomer.describeCustomer(
    {
      attributes: {
        emails: [kustomerData.emails],
      },
      customAttributes: {
        displayNameStr: kustomerData.nameStr,
        currentProductStr: kustomerData.currentProductStr,
        commandLinkStr: kustomerData.commandLinkStr,
        destinationsConnectedStr: kustomerData.destinationsConnectedStr,
        segmentationCategoryStr: kustomerData.segmentationCategoryStr,
        planNameStr: kustomerData.planNameStr,
        paymentTypeStr: kustomerData.paymentTypeStr,
        signedUpStr: kustomerData.signedUpStr,
        lastSeenStr: kustomerData.lastSeenStr,
        pageUrlStr: kustomerData.pageUrlStr,
        referrerStr: kustomerData.referrerStr,
        browserStr: kustomerData.browserStr,
      },
    },
    (resp: Response, err: Error) => {
      if (err) console.error(err)
    },
  )
}

interface Kustomer {
  emails: String
  nameStr?: String
  currentProductStr?: String
  commandLinkStr?: String
  destinationsConnectedStr?: String
  segmentationCategoryStr?: String
  planNameStr?: String
  paymentTypeStr?: String
  signedUpStr?: String
  lastSeenStr?: String
  pageUrlStr?: String
  referrerStr?: String
  browserStr?: String
}

export let useReferralHero = null as Promise<any>

export const initializeReferralHero: (uuid: string) => Promise<any> = (
  uuid,
) => {
  useReferralHero = new Promise((resolve, reject) => {
    // @ts-ignore
    window.RHConfig = {
      defaults: {
        form_container_id: 'maitre-widget', // The ID of the div where you want the form to appear.
        sharing_screen_container_id: 'maitre-sharing', // The ID of the div where you want the sharing screen. Useful only if you want the sharing screen to appear in a different part of the page. It doesn't work if the popup is enabled
      },
      settings: {
        track_events: false, //If custom events can be tracked
        one_click_signup: {
          enable: false, // Turn on/off the one-click-signup feature
          name: 'rh_name',
          email: 'rh_email', // The URL parameter used to extract the email
          extra_field: 'rh_extra_field',
          extra_field_2: 'rh_extra_field_2',
        },
        floating_button: {
          enable: false, // Whether or not to enable the Floating Button
          text: 'Join our Ambassador Program',
          color: '#1781bb',
          position: 'left', // Can be "left", "center" or "right"
        },
        design: {
          enable: true, // If disabled, the form will be loaded without a stylesheet
        },
        form: {
          cover: 'https://mywebsite.com/images/cover.jpg',
          header: { text: 'Sign up to win', color: '#1781bb' },
          name: { require: false, placeholder: '' },
          email: { placeholder: 'Your email' },
          extra_field: { require: false, placeholder: '' },
          extra_field_2: { require: false, placeholder: '' },
          submit_button: {
            text: 'Submit',
            check_position: 'Check status',
            submitting: 'Submitting...',
            color: '#1781bb',
          },
          status: { text: 'Check status', back: 'Back' },
          terms_conditions: {
            require: false,
            text: 'I accept the terms and conditions',
            url: 'https://mywebsite.com/legal/terms',
          },
        },
        sharing: {
          redirection: {
            enable: false, // Enabele/disable redirectoin after sign up
            url: '',
          },
          popup: true,
          true: true,
          position: { show: false, text: 'Your position', ordinal: true },
          referral_link: { copy_button: 'Copy', copied_button: 'Copied' },
          verification: {
            text: "Don't forget to confirm your email",
            reminder_email:
              "Your email hasn't been verified yet.<br>Check your inbox - including the junk folder - and if you don't find it click the link below to resend it.",
            resend_email: 'Resend confirmation email',
            resending_email: 'Sending email...',
            email_replace: 'confirm your email', // The string that will be replaced with a link to popular email providers. Try to signup with a @gmail.com email.
            email_resent: 'Email has been sent. Check your inbox.',
          },
          leaderboard: {
            show: false,
            position: 'Position',
            subscriber: 'Subscriber',
            points: 'Points',
            footnote: '1 referral = 1 point',
          },
        },
        alerts: {
          subscriber_not_found: 'Email not found.',
          subscriber_already_promoted: 'You have already been promoted.',
          form_incomplete:
            'Something is missing. Please fill out the form before submitting.',
          server_problem:
            'We are experiencing some issues on our server. Please try again.',
          failed_recaptcha: "It looks like you're a bot.",
          terms_conditions: 'You must accept the Terms & Conditions',
        },
      },
      callbacks: {
        ready() {
          // @ts-ignore
          resolve(window.RH)
        },
      },
    }

    // @ts-ignore
    function loadRHTracker(m, a, i, t, r, e) {
      if (m.RH) return
      ;(r = m.RH = {}),
        (r.uuid = t),
        (r.loaded = 0),
        (r.base_url = i),
        (r.queue = []),
        (m.rht = function () {
          r.queue.push(arguments)
        })
      e = a.getElementsByTagName('script')[0]
      let c = a.createElement('script')
      c.async = !0
      c.src = i + '/widget/' + t + '.js'
      e.parentNode.insertBefore(c, e)
    }

    // @ts-ignore
    loadRHTracker(window, document, 'https://app.referralhero.com', uuid)
  })

  return useReferralHero
}
