import React from 'react'
import * as Color from '../../helpers/colors'
import Icon, { IconName } from '../icons/Icon'

const ELLIPSIS_RULES: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 1.2, // Adds a buffer so hanging letters like "g" don't get cut off
}

const styles = ({
  selectable = false,
  cursor,
  paragraph,
  fontSize,
  fontWeight,
  color,
  colorWeight,
  textTransform,
  textAlign,
  letterSpacing,
  lineHeight,
  opacity,
  italic,
  bold,
  width,
  maxWidth,
  ellipsis,
  marginLeft,
  marginTop,
}: Partial<TextProps> | TextBuilderProps): React.CSSProperties => ({
  width,
  ...(maxWidth ? { maxWidth, display: 'inline-block' } : {}),
  ...(paragraph ? { lineHeight: 1.4 } : {}),
  fontSize,
  fontWeight: bold ? '700' : fontWeight,
  color: color ? Color[color](colorWeight ?? 0) : null,
  cursor,
  opacity,
  marginLeft,
  marginTop,
  textTransform,
  textAlign,
  letterSpacing: letterSpacing,
  fontStyle: italic ? 'italic' : 'inherit',
  flexWrap: 'wrap',
  userSelect: selectable ? 'text' : 'none',
  ...(ellipsis ? ELLIPSIS_RULES : {}),
  ...(lineHeight ? { lineHeight } : {}),
})

export type TextProps = {
  text: string | number
  paragraph?: boolean
  fontWeight?: number
  color?: Color.Type
  colorWeight?: number
  fontSize?: number
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
  textAlign?: 'left' | 'right' | 'center'
  italic?: boolean
  bold?: boolean
  selectable?: boolean
  letterSpacing?: number
  lineHeight?: number | string
  opacity?: number
  width?: number
  maxWidth?: number
  marginLeft?: number
  marginTop?: number
  ellipsis?: Boolean
  icon?: IconName
  iconColorWeight?: number
  href?: string
  cursor?: 'pointer' | 'default'
  iconColor?: Color.Type
  // Avoid style overrides
  style?: React.CSSProperties
}

type TextFragment =
  | [] // Line break
  | [string] // Text only
  | [string, Omit<TextProps, 'text'>] // Text with custom styles
  | [Omit<TextProps, 'text'> & { text?: string }] // Optional text/icon
export type TextBuilderProps = Omit<TextProps, 'text'> & {
  text: Array<TextFragment>
  insertSpaces?: boolean
  verticalCenter?: boolean
}
export const TextBuilder = (props: TextBuilderProps) => (
  <div
    style={{
      ...styles(props),
      ...(props.verticalCenter
        ? { display: 'flex', alignItems: 'center' }
        : {}),
    }}
  >
    {props.text.map(([textOrModifiers, modifiers = {}], i) => {
      const { insertSpaces = true } = props
      let text = textOrModifiers
      if (typeof textOrModifiers === 'object') {
        modifiers = textOrModifiers
        text = textOrModifiers.text
      }

      return typeof textOrModifiers === 'string' ||
        typeof textOrModifiers === 'object' ? (
        <span
          key={i}
          style={{
            ...styles(modifiers),
            marginRight: insertSpaces ? '0.25em' : '0',
          }}
        >
          {modifiers.icon && (
            <TextIcon {...modifiers} withMargin={Boolean(text)}></TextIcon>
          )}
          {modifiers.href ? (
            <span style={{ display: 'inline' }}>
              <a
                style={{
                  color: 'inherit',
                  background: 'inherit',
                  fontSize: 'inherit',
                  opacity: 'inherit',
                  position: 'relative',
                  textDecoration: 'none',
                  display: 'inline',
                }}
                target="_blank"
                href={modifiers.href}
              >
                {text}
              </a>
            </span>
          ) : (
            text
          )}
        </span>
      ) : (
        <br key={i} />
      )
    })}
  </div>
)

const TextIcon = (props: Omit<TextProps, 'text'> & { withMargin: boolean }) => {
  return (
    <>
      <span
        style={{
          display: 'inline-flex',
          marginRight: props.withMargin ? '0.3em' : 0,
          position: 'relative',
          top: '0.12em',
        }}
      >
        <Icon
          name={props.icon}
          color={props.iconColor}
          colorWeight={props.iconColorWeight}
        />
      </span>
    </>
  )
}

export const CustomText = (props: TextProps) => (
  <div style={{ ...styles(props), ...props.style }}>
    {props.icon && (
      <TextIcon {...props} withMargin={Boolean(props.text)}></TextIcon>
    )}
    {props.text}
  </div>
)

const Heading1 = (props: TextProps) => {
  return <CustomText fontSize={24} lineHeight={1} fontWeight={900} {...props} />
}

const Heading2 = (props: TextProps) => {
  return (
    <CustomText fontSize={20} lineHeight={1.1} fontWeight={900} {...props} />
  )
}

const Heading3 = (props: TextProps) => {
  return (
    <CustomText fontSize={16} lineHeight={1.2} fontWeight={300} {...props} />
  )
}

const Body = (props: TextProps) => {
  return (
    <CustomText fontSize={14} lineHeight={1.2} fontWeight={300} {...props} />
  )
}

const Caption1 = (props: TextProps) => {
  return (
    <CustomText fontSize={12} lineHeight={1.2} fontWeight={300} {...props} />
  )
}

const Caption2 = (props: TextProps) => {
  return (
    <CustomText fontSize={10} lineHeight={1.2} fontWeight={400} {...props} />
  )
}

const Link = (props: TextProps) => {
  return (
    <div style={{ ...styles(props), ...props.style }}>
      <a
        href={props.href}
        style={{ ...styles(props), ...props.style }}
        target="_blank"
      >
        {props.text}
      </a>
    </div>
  )
}

const Label = (props: TextProps) => {
  return (
    <CustomText
      fontSize={14}
      lineHeight={1}
      fontWeight={400}
      color="neutral"
      colorWeight={600}
      ellipsis={true}
      {...props}
    />
  )
}

const Custom = CustomText

export default {
  Heading1,
  Heading2,
  Heading3,
  Caption1,
  Caption2,
  Body,
  Label,
  Link,
  Custom,
}
